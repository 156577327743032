import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  baanerBox: {
    position: "relative",
    paddingBottom:"100px",
  },
  textbox: {
    position: "relative",
    zIndex: "2",
    "& button": {
      "&:last-child": {
        marginLeft: "20px",
      },
    },
    "& h1": {
      fontSize: "40px",
      fontWeight: "600",
      lineHeight: "60px",
      color: "#fff",
      [theme.breakpoints.down("lg")]: {
        fontSize: "40px",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "30px",
        lineHeight: "40px",
      },
    },
    "& p": {
      fontSize: "16px",
      marginTop: "20px",
      color: "#fff ",
      lineHeight: "24px",
      fontWeight: 400,
      [theme.breakpoints.down("xs")]: {
        fontSize: "16px",
      }
    },

  },
  Nftimg: {
    position: "relative",
    zIndex: "2",
    textAlign: "right",
    "& img": {
      width: "100%",
      maxWidth: "80%",
      margin: "0 auto",
    },
  },
  bgimg: {
    position: "absolute",
    zIndex: "-1",
    top: "50%",
    transform: " translateY(-50%)",
    left: "-150px",
  },
}));
