import React, { useState } from "react";
import { Grid, Button, Typography, Box } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import useStyles from "./styles";
import PageTitle from "../../components/PageTitle/PageTitle";
import PublicMint from "./PublicMint";
import HowWorks from "./HowWorks";
import { useHistory } from "react-router";
import Mint from "./Mint";
export default function Dashboard(props) {
  var classes = useStyles();
  var theme = useTheme();
  const history = useHistory();
  return (
    <>
      <Box className={classes.baanerBox}>
        <Box>
          <Grid container spacing={3} alignItems='center'>
            <Grid item={12} md={12} lg={6}>
              <Box className={classes.textbox}>
                <img src='../images/banner-bg.png' className={classes.bgimg} />
                <Typography variant='h1'>
                  Dex Daddy ipsum <br />
                  <span style={{ color: "#F8B900" }}>
                    decentralized Mint NFT
                  </span>
                </Typography>
                <Typography variant='body1'>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type{" "}
                </Typography>
                <Box mt={4}>
                  <Button
                    onClick={() => {
                      history.push("/app/mint");
                    }}
                    variant='contained'
                    size='large'
                    color='secondary'
                  >
                    MINT
                  </Button>
                  <Button
                    variant='contained'
                    size='large'
                    color='primary'
                    className={classes.buttonright}
                    onClick={() =>
                      window.open(
                        "https://medium.com/theta-network/theta-blockhain-now-accessible-through-metamask-plug-in-61b278633264"
                      )
                    }
                  >
                    DEMO
                  </Button>
                </Box>
              </Box>
            </Grid>
            <Grid item={12} md={12} lg={6}>
              <Box className={classes.Nftimg}>
                <img src='../images/banner-img.png' />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <PublicMint />
      <Mint />
      <HowWorks />
    </>
  );
}
