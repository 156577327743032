import React, { useState, useContext, useRef } from "react";
import { AppBar, Toolbar, IconButton, Grid, Box, Hidden } from "@material-ui/core";
import { Link } from "react-router-dom";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreAction from "./MoreAction";
import {
  Menu as MenuIcon,
  ArrowBack as ArrowBackIcon,
} from "@material-ui/icons";
import classNames from "classnames";
// styles
import useStyles from "./styles";
// components
import { Button } from "../Wrappers";
// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";
import { BiDotsVertical } from "react-icons/bi";
import { useUserDispatch, signOut } from "../../context/UserContext";
import { UserContext } from "../../context/User";
import { useWeb3React } from "@web3-react/core";
import MenuLink from "./MenuLink"
export default function Header(props) {
  var classes = useStyles();
  const user = useContext(UserContext);
  // global
  var layoutState = useLayoutState();
  var layoutDispatch = useLayoutDispatch();
  const { active, account, chainId, library } = useWeb3React();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const moreRef = useRef(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <AppBar position='fixed' className={classes.appBar} elevation={0} style={{backgroundColor:"#000 !important"}}>
      <Grid container spacing={2} alignItems='center'>
        <Grid item xs={5} sm={2}>
          <Toolbar className={classes.toolbar}>
            <IconButton
              color='inherit'
              onClick={() => toggleSidebar(layoutDispatch)}
              className={classNames(
                classes.headerMenuButtonSandwich,
                classes.headerMenuButtonCollapse
              )}
            >
              {layoutState.isSidebarOpened ? (
                <ArrowBackIcon
                  classes={{
                    root: classNames(
                      classes.headerIcon,
                      classes.headerIconCollapse
                    ),
                  }}
                />
              ) : (
                <MenuIcon
                  classes={{
                    root: classNames(
                      classes.headerIcon,
                      classes.headerIconCollapse
                    ),
                  }}
                />
              )}
            </IconButton>
            <Hidden smUp>
              <Link to='/'>
                {" "}
                <img src='../images/fav.png' />
              </Link>
            </Hidden>
            <Hidden xsDown>
              <Link to='/'>
                {" "}
                <img src='../images/logo.png' />
              </Link>
            </Hidden>

            <div className={classes.grow} />
          </Toolbar>
        </Grid>
        <Grid item xs={7} sm={10} align='right' className={classes.sidbar}>
          <Box className={classes.sidbar2}>
            <Hidden smDown>
              <MenuLink />
            </Hidden>
            <Hidden mdUp>
              <MoreAction />
            </Hidden>
            {account ? (
              <Link className={classes.navLinks} to='/app/wallet'>
                Wallet
              </Link>
            ) : (
              <button
                className={classes.buttonright}
                variant='contained'
                size='large'
                onClick={user.connectWallet}
                style={{ color: "#fff !important" }}
              >
                CONNECT
              </button>
            )}
          </Box>
        </Grid>
      </Grid>
    </AppBar>
  );
}
