import React, { useState, useContext, useEffect } from "react";
import {
  makeStyles,
  Typography,
  Box,
  Container,
  ListItem,
  Button,
  List,
} from "@material-ui/core";
import Slider from "@material-ui/core/Slider";
import { UserContext } from "../../context/User";
function valuetext(value) {
  return `${value}°C`;
}
const useStyles = makeStyles((theme) => ({
  textbox: {
    // backgroundImage: "url('../images/time-bg.png')",
    // backgroundRepeat: "no-repeat",
    // backgroundSize: "cover",
    borderRadius: "5px",
    boxShadow:" rgb(255 195 0) 0px 0px 16px 4px inset",
    textAlign: "center",
    border: "1px solid #7C5C00",
    padding: "30px",
    overflow:"hidden",
    position:"relative",
    "& h1": {
      fontSize: "46px",
      fontWeight: "600",
      lineHeight: "67px",
      color: "#fff",
      [theme.breakpoints.down("lg")]: {
        fontSize: "46px",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "30px",
      },
    },
  },
  time: {
    width: "100%",
    maxWidth: "600px",
    margin: "0 auto",
    display: "flex",
    marginTop: "50px",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      marginTop: "0px",
    },
    "& li": {
      display: "block",
      padding: "10px",
      textAlign: "center",
      "& span": {
        color: "#fff",
        [theme.breakpoints.down("xs")]: {
          fontSize: "10px",
        },
      },
      "& div": {
        width: "80px",
        height: "80px",
        position: "relative",
        margin: "auto",
        marginTop: "10px",
        backgroundColor: "#222222",
        display: "flex",
        alignItems: " center",
        justifyContent: "center",
        fontSize: "35px",
        color: "#fff",
        fontWeight: "bold",
        borderRadius: " 10px",
        zIndex: "1",
        [theme.breakpoints.down("xs")]: {
          width: "40px",
          height: "40px",
          fontSize: "16px",
        },
      },
    },
  },
  slider: {
    marginTop: "130px",
    width: "100%",
    margin: "0 auto ",
    maxWidth: "70%",
    [theme.breakpoints.down("xs")]: {
      maxWidth: "100%",
      marginTop: "50px",
    },
    "& h3": {
      fontSize: "35px",
      color: "#fff",
      fontWeight: "600",
      marginBottom: "20px",
      [theme.breakpoints.down("xs")]: {
        fontSize: "20px",
      },
    },
  },
}));

const calculateTimeLeft = () => {
  let year = new Date().getFullYear();
  let difference = +new Date(`12/12/${year}`) - +new Date();
  let timeLeft = {};

  if (difference > 0) {
    timeLeft = {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };
  }
  return timeLeft;
};

export default function Dashboard(props) {
  var classes = useStyles();
  const user = useContext(UserContext);
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
    return () => clearTimeout(timer);
  });
  return (
    <Box className={classes.baanerBox}>
      
      <Container maxWidth='md'>
        <Box className={classes.textbox}>
        <Box className="animationbox"></Box>
          <Typography variant='h1'>Public Mint Opening </Typography>
          <Typography></Typography>
          <List className={classes.time}>
            <ListItem>
              <span>DAY</span>
              <div>{timeLeft.days}</div>
            </ListItem>
            <ListItem>
              <span>HOURS</span>
              <div>{timeLeft.hours}</div>
            </ListItem>
            <ListItem>
              <span>MINUTES</span>
              <div>{timeLeft.minutes}</div>
            </ListItem>
            <ListItem>
              <span>SECONDS</span>
              <div>{timeLeft.seconds}</div>
            </ListItem>
          </List>
          <Box align='center' className='wow bounceInUp' mt={5}>
            <Button variant='contained' size='large' color='secondary'>
              MINTING SOON
            </Button>
          </Box>
        </Box>

        <Box className={classes.slider} mb={5}>
          <Typography variant='h3' align='center'>
            {" "}
            {user.totalSupply} of {user.maxSupply} Minted
          </Typography>
          <Box mb={3}>
            <Slider
              defaultValue={0}
              getAriaValueText={valuetext}
              aria-labelledby='discrete-slider'
              valueLabelDisplay='auto'
              value={parseInt(user.totalSupply)}
              step={1}
              marks
              min={0}
              max={parseInt(user.maxSupply)}
            />
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
