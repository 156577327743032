import React, { useEffect, useState, useContext } from "react";
import {
  Box,
  Container,
  Typography,
  makeStyles,
  Grid,
} from "@material-ui/core";
import { BiCopy } from "react-icons/bi";
// import Transfer from "src/component/Transfer";
import { useWeb3React } from "@web3-react/core";
import { useHistory } from "react-router";
import { UserContext } from "../../context/User";
import NFTCard from "../../components/NFT/NFTCard";
import NoDataFound from "../../components/NoDataFound/NoDataFound";
import { toast } from "react-toastify";
import { getWeb3ContractObject } from "../../utils";
import { Finalcontractaddress } from "../../constants";
import ThetaPunkABI from "../../abis/ThetaPunkABI.json";
const useStyles = makeStyles((theme) => ({
  deatailimage: {
    width: "100%",
    height: "50vh",
    display: "flex",
    padding: "50px 0",
    position: "relative",
    background: "linear-gradient(  342deg, #180f07 39%, #30140de8 61%)",
    textAlign: "center",
    justifyContent: "center",
    borderBottom: " 2px solid #4A4A4A",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    [theme.breakpoints.down("sm")]: {
      height: "auto",
    },
    "& img": {
      filter: " drop-shadow(rgba(0, 0, 0, 0.25) 0px 20px 20px)",
      maxHeight: "100%",
      borderRadius: "10px",
      maxWidth: "100%",
    },
  },

  Padding_Top: {
    paddingTop: "50px",
    backgroundColor: "#fff",
  },
  dialogBox: {
    padding: "30px",
  },
  walletPage: {
    "& h4": {
      fontSize: "50px",
      fontWeight: "600",
      color: "#300760",
      marginBottom: "30px",
      "& span": {
        color: "#f30066",
      },
      [theme.breakpoints.down("lg")]: {
        fontSize: "40px",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "30px",
      },
    },
    "& p": {
      fontWeight: "500",
      fontSize: "16px",
      lineHeight: "27px",
      color: "#7f7f7f",

      "& span": {
        color: "#ec0066",
        cursor: "pointer",
      },
    },
  },
  paper: {
    overflowY: "unset",
  },
  customizedButton: {
    position: "absolute",
    top: "-42px",
    right: "-9px",
    color: "#fff",
  },
  walletBox: {
    background: "#FFFFFF",
    boxShadow: " 0px 4px 8px rgba(0, 0, 0, 0.12)",
    borderRadius: "25px",
    padding: "30px",
    textAlign: "center",
    marginBottom: "50px",
    transition: "02s",
    cursor: "pointer",
    border: "1px solid transparent",
    "&:hover": {
      border: "1px solid #f30065",
    },
    "& p": {
      fontWeight: "500",
      fontSize: "16px",
      lineHeight: "27px",
      color: "#fafafa",
      textOverflow: "ellipsis",
      maxWidth: "90%",
      overflow: "hidden",
      position: "relative",
    },
  },
  copy: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    boxShadow: " 0px 4px 8px rgb(0 0 0 / 12%)",
    backgroundColor: "#fbc433",
    color: "#000",
    paddingTop: "13px",
    position: "absolute",
    right: "10%",
    cursor: "pointer",
    padding: "10px",
    borderRadius: "50px",
    bottom: "-26px",
    [theme.breakpoints.down("xs")]: {
      right: "10px",
      width: "90%",
    },
    "& svg": {
      fontSize: "30px",
    },
  },
  walletdiv: {
    background: "#222222",
    boxShadow: " 0px 4px 8px rgba(0, 0, 0, 0.12)",
    borderRadius: "10px",
    padding: "20px 15px",
    position: "relative",
    // backgroundColor: "#dedede",
    border: "1px solid transparent",
    overflow: "hidden",
    "& svg": {
      position: "absolute",
      right: "24px",
      fontSize: "80px",
      top: "9px",
      color: "#3c076a40",
      transform: "rotate(-20deg)",
    },
    "& h6": {
      color: "#fff",
    },
    "& h1": {
      color: "#fff",
      marginTop: "10px",
    },
    "&:hover": {
      "& .wallet_box": {
        opacity: "1",
        top: "30%",
        right: "-60px",
      },
      "& .wallet_box:first-child": {
        opacity: "1",
        top: "30%",
        right: "-60px",
      },
    },
  },
  box: {
    height: "150px",
    width: "150px",
    borderRadius: "50%",
    backgroundColor: "#ebae2f38",
    position: "absolute",
    top: "100%",
    right: "-150px",
    transition: "0.5s all",
  },
}));
const Card = [
  {
    image: "images/Mint/1.png ",
    name: "Lorem Ipsum  ",
    url: "/details",
  },
  {
    image: "images/Mint/2.png",
    name: "Lorem Ipsum  ",
    url: "/details",
  },
  {
    image: "images/Mint/3.png ",
    name: "Lorem Ipsum  ",
    url: "/details",
  },
  {
    image: "images/Mint/4.png ",
    name: "Lorem Ipsum ",
    url: "/details",
  },
];
function Wallet(props) {
  const classes = useStyles();
  const { account, chainId } = useWeb3React();
  const auth = useContext(UserContext);
  const [isChangingState, setIsChangingState] = useState(false);
  const [isSaleActive, setIsSaleActive] = useState(false);

  const history = useHistory();
  useEffect(() => {
    if (!account) {
      history.push("/");
    }
  }, [account]);

  function myFunction() {
    var copyText = document.getElementById("myInput");
    copyText.select();
    copyText.setSelectionRange(0, 99999); /* For mobile devices */
    navigator.clipboard.writeText(copyText.value);
    toast.info(`Copied ${copyText.value}`);
  }

  const flipSaleStateHanlder = async () => {
    try {
      setIsChangingState(true);
      const contract = getWeb3ContractObject(
        ThetaPunkABI,
        Finalcontractaddress
      );
      const flipSaleState = await contract.methods.flipSaleState().send({
        from: account,
      });
      console.log("flipSaleState", flipSaleState);
      setIsSaleActive(!auth.isSaleActive);
      setIsChangingState(false);
    } catch (error) {
      setIsChangingState(false);
      toast.error(JSON.stringify(error));
      console.log("EROR", error);
    }
  };

  return (
    <>
      {/* {account && ( */}
      <Box style={{ backgroundColor: "#000" }} mb={5}>
        <Box
          className={classes.deatailimage}
          style={{ backgroundImage: "url('images/bg-date.png')" }}
          mb={10}
        >
          <img
            src={`https://chart.googleapis.com/chart?chs=300x300&cht=qr&chl=${account}&choe=UTF-8`}
            alt=''
          />
          <Box className={classes.copy}>
            <input id={"myInput"} value={account} style={{ display: "none" }} />
            <Typography
              variant='body1'
              align='center'
              onClick={() => myFunction()}
            >
              {account}
              &nbsp;
            </Typography>
            <BiCopy onClick={() => myFunction()} />
          </Box>
        </Box>

        <Container>
          <Box mt={5} mb={5}>
            <Grid container spacing={3} alignItems='center'>
              {/* <Grid item xs={12} sm={12} md={2}></Grid> */}
              {/* <Grid item xs={12} sm={6} md={4}>
                <Box className={classes.walletdiv}>
                  <Typography variant='h6'>Wallet NCT</Typography>
                  <Typography variant='h1'>0.0</Typography>
                  <Box className={`${classes.box} wallet_box`}></Box>
                </Box>
              </Grid> */}
              <Grid item xs={12} sm={6} md={4}>
                <Box className={classes.walletdiv}>
                  <Typography variant='h6'>Owned Punks</Typography>
                  <Typography variant='h1'>{auth.userTotalNFTS}</Typography>
                  <Box className={`${classes.box} wallet_box`}></Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={2}></Grid>
            </Grid>
          </Box>
          <Typography variant='h3' align='center'>
            ***All NFTs will be displayed once the admin stores their
            Metadata***
          </Typography>

          <Box mt={8}>
            <Grid container spacing={3}>
              {auth.nftList.map((data, i) => {
                return (
                  <Grid item xs={12} sm={6} md={3} key={i}>
                    <NFTCard isChange={false} data={data} index={i} />
                  </Grid>
                );
              })}
            </Grid>
          </Box>
          {auth.nftList.length === 0 && (
            <Box>
              <NoDataFound />
            </Box>
          )}
        </Container>
      </Box>
      {/* )} */}
    </>
  );
}

export default Wallet;
