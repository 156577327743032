export const NetworkContextName = "Avalanche Test";
export const ACTIVE_NETWORK = 43113;
export const Finalcontractaddress =
  "0x663788a7B39Ad13fcA8F3AaB8f7ab5E2d57f106A";
export const RPC_URL = "https://api.avax-test.network/ext/bc/C/rpc";

export const NetworkDetails = [
  {
    chainId: "0xA869",
    chainName: "Avalanche FUJI C-Chain",
    nativeCurrency: {
      name: "AVAX",
      symbol: "AVAX",
      decimals: 18,
    },
    rpcUrls: ["https://api.avax-test.network/ext/bc/C/rpc"],
    blockExplorerUrls: ["https://testnet.snowtrace.io/"],
  },
];
