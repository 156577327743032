import React, { createContext, useEffect, useState } from "react";
import { injected } from "../connectors";
import { useWeb3React } from "@web3-react/core";
import {
  ACTIVE_NETWORK,
  Finalcontractaddress,
  NetworkDetails,
} from "../constants/";
import ThetaPunkABI from "../abis/ThetaPunkABI.json";
import axios from "axios";
import { getWeb3ContractObject, getWeb3Obj, getContract } from "../utils";
import { toast } from "react-toastify";

export const UserContext = createContext();

const setSession = (userAddress) => {
  if (userAddress) {
    sessionStorage.setItem("userAddress", userAddress);
  } else {
    sessionStorage.removeItem("userAddress");
  }
};

export default function AuthProvider(props) {
  const { activate, active, account, library, chainId } = useWeb3React();
  const [nftList, setNftList] = useState([]);
  const [userTotalNFTS, setUserTotalNFTS] = useState(0);
  const [totalSupply, setTotalSupply] = useState(0);
  const [maxSupply, setMaxSupply] = useState(0);
  const [nftprice, setNftprice] = React.useState();
  const [isSaleActive, setIsSaleActive] = useState(false);

  console.log("ACTIVE_NETWORK.toString(16)", ACTIVE_NETWORK.toString(16));
  useEffect(() => {
    if (account && chainId) {
      if (chainId !== ACTIVE_NETWORK) {
        if (window.ethereum) {
          swichNetworkHandler();
        }
      }
    }
  }, [chainId, account]);

  const swichNetworkHandler = async () => {
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: "0x" + ACTIVE_NETWORK.toString(16) }],
      });
    } catch (error) {
      console.log("ERROR", error);
      toast.warn(error.message);
      if (error.code === 4902) {
        addNetworkHandler();
      }
    }
  };

  const addNetworkHandler = async () => {
    try {
      await window.ethereum.request({
        method: "wallet_addEthereumChain",
        params: NetworkDetails,
      });
    } catch (error) {
      console.log("ERROR", error);
      toast.warn(error.message);
    }
  };

  let data = {
    nftList,
    userTotalNFTS,
    maxSupply,
    totalSupply,
    isSaleActive,
    nftprice,
    getGalleryData: () => Gallery(),
    getMoreData: () => getMoreData(),
    updateUser: (account) => {
      setSession(account);
    },
    connectWallet: async () => {
      try {
        activate(injected, undefined, true).catch((error) => {
          if (error) {
            console.log("ERROR", error);
            const errorMSG = error.message; //+ ' Please install Metamask';
            toast.warn(errorMSG);
            // alert(errorMSG);
            activate(injected);
          }
        });
      } catch (error) {
        console.log("ERROR", error);
      }
    },
  };

  const Gallery = async () => {
    setNftList([]);
    const contract = getContract(
      Finalcontractaddress,
      ThetaPunkABI,
      library,
      account
    );
    const txaCount = await contract.balanceOf(account);
    const txa = txaCount.toString();
    setUserTotalNFTS(txa);

    try {
      for (let i = 0; i < txa; i++) {
        const id = await contract.tokenOfOwnerByIndex(account, i);
        const filter = await contract.tokenURI(id.toString());
        const res = await axios.get(filter);
        if (res.status === 200) {
          setNftList((prev) => [
            ...prev,
            { id: id.toString(), nfdData: res.data },
          ]);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };
  React.useEffect(() => {
    if (active === true && account && chainId === ACTIVE_NETWORK) {
      Gallery();
      getMoreData();
    }
  }, [account, active, chainId]);

  React.useEffect(() => {
    getMoreData();
  }, [account]);

  const getMoreData = async () => {
    try {
      const web3 = await getWeb3Obj();
      const contract = await getWeb3ContractObject(
        ThetaPunkABI,
        Finalcontractaddress
      );
      const totalSupp = await contract.methods.totalSupply().call();
      console.log("totalSupp", totalSupp);
      setTotalSupply(parseFloat(totalSupp.toString()));
      const MAX_PUNKS = await contract.methods.MAX_PUNKS_SUPPLY().call();
      setMaxSupply(parseFloat(MAX_PUNKS.toString()));
      console.log("MAX_PUNKS", MAX_PUNKS);
      const saleIsactive = await contract.methods.saleIsActive().call();
      console.log("saleIsactive", saleIsactive);
      const NFTP = await contract.methods.getPUNKPrice().call();
      console.log("saleIsactive", saleIsactive);
      const etherValue = await web3.utils.fromWei(NFTP.toString());
      setNftprice(parseFloat(etherValue));
      setIsSaleActive(saleIsactive);
    } catch (error) {
      console.log("ERROR", error);
    }
  };

  useEffect(() => {
    const userAddress = window.sessionStorage.getItem("userAddress");
    if (userAddress) {
      data.connectWallet();
    }
  }, []); //eslint-disable-line

  useEffect(() => {
    data.updateUser(account);
  }, [account]); //eslint-disable-line

  return (
    <UserContext.Provider value={data}>{props.children}</UserContext.Provider>
  );
}
