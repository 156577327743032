import React from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import classnames from "classnames";
import {
  Box,
  IconButton,
  Link,
  Grid,
  makeStyles,
  Button,
  TextField,
  Typography as MaterilTypography,
} from "@material-ui/core";
import Icon from "@mdi/react";
import { FaLinkedinIn } from "react-icons/fa";
import { FaTelegramPlane } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import { AiOutlineGooglePlus } from "react-icons/ai";
import { AiOutlineInstagram } from "react-icons/ai";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
//icons
import {
  mdiFacebook as FacebookIcon,
  mdiTwitter as TwitterIcon,
  mdiGithub as GithubIcon,
} from "@mdi/js";

// styles

// components
import Header from "../Header";
import Sidebar from "../Sidebar";
import { useWeb3React } from "@web3-react/core";

// pages
import Dashboard from "../../pages/dashboard";
import Typography from "../../pages/typography";
import UserSearchNFT from "../../pages/UserSearchNFT/";
import NFTDetails from "../../pages/NFTDetails/";
import MyWallet from "../../pages/Wallet/Index";
import MintNFT from "../../pages/MintNFT/";
import Attributes from "../../pages/Attributes/";
// import Notifications from "../../pages/notifications";
// import Maps from "../../pages/maps";
// import Tables from "../../pages/tables";
// import Test from "../../pages/test";
// import Icons from "../../pages/icons";
// import Charts from "../../pages/charts";
// context
import { useLayoutState } from "../../context/LayoutContext";
import { ACTIVE_NETWORK, NetworkContextName } from "../../constants";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    maxWidth: "100vw",
    overflowX: "hidden",
  },
  content: {
    flexGrow: 1,
    padding: "24px 150px",
    width: `calc(100vw - ${240 + theme.spacing(6)}px)`,
    width: `calc(100vw - 240px)`,
    minHeight: "100vh",
    paddingTop: "80px",
    [theme.breakpoints.down("md")]: {
      padding: "24px 24px",
      paddingTop: "80px",
    },
  },
  contentShift: {
    width: `calc(100vw - 240px)`,

    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  fakeToolbar: {
    ...theme.mixins.toolbar,
  },
  link: {
    "&:not(:first-child)": {
      paddingLeft: 15,
    },
  },

  footerSection: {
    "& h6": {
      margin: 0,
      fontWeight: "600",
      fontSize: "18px",
      color: "#F8B900",
      marginBottom: "20px",
      [theme.breakpoints.down('xs')]:{
        fontSize: "14px",
      },
    },
    "& ul": {
      listStyle: "none",
      paddingLeft: "0",
      margin: "0",
      "& li": {
        marginBottom: "8px",
      
        "& a": {
          fontSize: "14px",
          color: "#848484",
          [theme.breakpoints.down('xs')]:{
            fontSize: "12px",
          },
          "&:hover": {
            textDecoration: "none",
            color:"#F8B900",
          },
        },
      },
    },
  },
}));
function Layout(props) {
  const classes = useStyles();
  // global
  var layoutState = useLayoutState();
  const { account, active, library, chainId } = useWeb3React();
  return (
    <div className={classes.root}>
      <>
        <Header history={props.history} />
        <Sidebar />
        <div
          className={classnames(classes.content, {
            [classes.contentShift]: layoutState.isSidebarOpened,
          })}
        >
          {/* <div className={classes.fakeToolbar} /> */}
          <Switch>
            <Route path='/app/dashboard' component={Dashboard} />
            <Route path='/app/search-NFT' component={UserSearchNFT} />
            <Route path='/app/NFT-details' component={NFTDetails} />
            <Route path='/app/wallet' component={MyWallet} />
            <Route path='/app/mint' component={MintNFT} />
            <Route path='/app/typography' component={Dashboard} />
            <Route path='/app/attributes' component={Attributes} />

            {/* <Route path="/app/tables" component={Tables} /> */}
            {/* <Route path="/app/test" component={Test} /> */}
            {/* <Route path="/app/notifications" component={Notifications} /> */}
            <Route
              exact
              path='/app/ui'
              render={() => <Redirect to='/app/ui/icons' />}
            />
            {/* <Route path="/app/ui/maps" component={Maps} />
            <Route path="/app/ui/icons" component={Icons} />
            <Route path="/app/ui/charts" component={Charts} /> */}
          </Switch>

          <Box className={classes.footerSection}>
            <Grid
              style={{ borderBottom: "1px solid gray", padding: "10px 1px" }}
              container
            
              spacing={1}
            >
              <Grid item item xs={12} sm={12} md={12} lg={3}>
                <img alt='' src='images/logo.png' />
                <Box align='left' mt={3} mb={3} className='follow'>
                  <Link href='#' target='_blank'>
                    <FaFacebookF />
                  </Link>
                  <Link href='#' target='_blank'>
                    <FaLinkedinIn />
                  </Link>
                  <Link href='#' target='_blank'>
                    <FaTelegramPlane />
                  </Link>
                  <Link href='#' target='_blank'>
                    <AiOutlineGooglePlus />
                  </Link>
                  <Link href='#' target='_blank'>
                    <AiOutlineInstagram />
                  </Link>
                </Box>
              </Grid>
              <Grid item item xs={6} sm={4} md={4} lg={2} align='left'>
                <Box class=' col-lg-4 col-md-4 col-sm-6 col-12'>
                  <h6>Services</h6>
                  {/* <Typography variant="h5">Services</Typography> */}
                  <ul className='list-unstyled footer_text'>
                    <li>
                      <Link href='#'>IDO Launchpad</Link>
                    </li>
                    <li>
                      <Link href='#'>Dex</Link>
                    </li>
                    <li>
                      <Link href='#'>NFT</Link>
                    </li>
                    <li>
                      <Link href='#'>Gaming </Link>
                    </li>
                    <li>
                      <Link href='#'>Yield Farming</Link>
                    </li>
                    <li>
                      <Link href='#'>Market Place</Link>
                    </li>
                  </ul>
                </Box>
              </Grid>
              <Grid item item xs={6} sm={4} md={4} lg={2} align='left'>
                <Box class=' col-lg-4 col-md-4 col-sm-6 col-12'>
                  <h6>About us </h6>
                  {/* <Typography variant="h5">About us </Typography> */}
                  <ul class='list-unstyled footer_text'>
                    <li>
                      <Link href='#'>Company</Link>
                    </li>
                    <li>
                      <Link href='#'>White Paper </Link>
                    </li>
                    <li>
                      <Link href='#'>Career </Link>
                    </li>
                    <li>
                      <Link href='#'>Privacy Policy</Link>
                    </li>
                    <li>
                      <Link href='#'>Terms & Conditions </Link>
                    </li>
                    <li>
                      <Link href='#'>Disclaimer</Link>
                    </li>
                  </ul>
                </Box>
              </Grid>

              <Grid item item xs={6} sm={4} md={4} lg={2} align='left'>
                <Box class=' col-lg-4 col-md-4 col-sm-6 col-12'>
                  <h6>Support</h6>
                  {/* <Typography variant="h5">Support</Typography> */}

                  <ul class='list-unstyled footer_text'>
                    <li>
                      <Link href='#'>FAQs</Link>
                    </li>
                    <li>
                      <Link href='#'>Support</Link>
                    </li>
                  </ul>
                </Box>
              </Grid>

              <Grid item item xs={12} sm={6} md={6} lg={3} align='left'>
              <h6>Subscribe more info</h6>
                <TextField id="filled-basic"  variant="filled" fullWidth  placeholder='Enter your Email' />
                <Button
                  style={{ marginTop: "18px" }}
                  variant='contained'
                  size='large'
                  color='secondary'
                >
                  Subscribe
                </Button>
              </Grid>
            </Grid>

            <p align='center'>2018@ companyLTD. All Right reserved</p>
          </Box>
        </div>
      </>
      {account && (
        <Dialog
          open={account && chainId !== ACTIVE_NETWORK}
          minWidth='md'
          fullWidth
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle id='alert-dialog-title'>
            {"Netwok Change Error"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>
              <Box ml={3} pb={3}>
                <MaterilTypography style={{ color: "black" }}>
                  Please switch to {NetworkContextName} network
                </MaterilTypography>
              </Box>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
}

export default withRouter(Layout);
