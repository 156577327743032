import React, { useState } from "react";
import {
    makeStyles,
    Typography,
    Box,
    Container,
    ListItem,
    Button,
    Grid,
    List,
} from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import Slider from '@material-ui/core/Slider';
function valuetext(value) {
    return `${value}°C`;
}
const useStyles = makeStyles((theme) => ({
    baanerBox:{
        paddingBottom:"150px",
    },
    slider: {
        "& h3": {
            fontSize: "35px",
            color: "#FFC400",
            fontWeight: "600",
            marginBottom: "20px",
            [theme.breakpoints.down("xs")]: {
                fontSize: "20px",
            },
        },
    },
    workBox: {
        width: "100%",
        position: "relative",
        borderRadius:"10px",
        // backgroundImage: "url('../images/card-bg.png')",
        padding: "20px",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        transition: "0.5s",
        cursor:"pointer",
        "&:hover":{
            transform:" translateY(-15px )",
        },
        "& h4": {
            color: "#fff",
            fontWeight: "bold",
            fontSize: "20px",
            textAlign: "center",
            marginBottom: "10px",
        },
        "& p": {
            color: "#fff",
            fontSize: "16",
            textAlign: "left",
        },
        "& label": {
            color: "rgba(255, 255, 255, 0.5)",
            position: "absolute",
            fontWeight: "bold",
            fontSize: "30px",
            top: "20px",
            left: "20px",
        },
    },
}));
export default function Dashboard(props) {
    var classes = useStyles();
    var theme = useTheme();
    return (
        <Box className={classes.baanerBox} mt={7}>
            <Box className={classes.slider} mb={5} >
                <Typography variant="h3" align="center" > How it Works</Typography>
            </Box>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4} >
                 <Box style={{    backgroundImage: "linear-gradient(to top, rgb(247 21 86 / 62%), rgb(228 150 58 / 82%))", borderRadius:"10px",}}>
                 <Box className={classes.workBox} style={{backgroundImage: "url('../images/1.svg')",}}>
                        
                        <Typography variant="h4">Lorem iosum heading</Typography>
                        <Typography variant="body2"> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has beenLorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has beenLorem Ipsum is simply dummy text of the printing and
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. </Typography>
                    </Box>
                 </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}> 
                <Box style={{    backgroundImage: "linear-gradient(to top, rgb(112 111 190), rgb(105 16 146))", borderRadius:"10px",}}>
                    <Box className={classes.workBox} style={{backgroundImage: "url('../images/2.svg')",}}>
                       
                        <Typography variant="h4">Lorem iosum heading</Typography>
                        <Typography variant="body2"> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has beenLorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has beenLorem Ipsum is simply dummy text of the printing and
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. </Typography>
                    </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                <Box style={{    backgroundImage: "linear-gradient(to top, rgb(17 97 253 / 50%), rgb(0 154 111 / 52%))", borderRadius:"10px",}}>
                    <Box className={classes.workBox} style={{backgroundImage: "url('../images/3.svg')",}}>
                       
                        <Typography variant="h4">Lorem iosum heading</Typography>
                        <Typography variant="body2"> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has beenLorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has beenLorem Ipsum is simply dummy text of the printing and
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. </Typography>
                    </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}

